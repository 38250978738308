import * as React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { tablet } from 'shared/media-queries'

const StyledGatsbyImage = styled(GatsbyImage)`
	${tablet} {
		max-width: 500px;
		margin: 0 auto;
	}
`

const QualifiedBuildersImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				image: file(
					relativePath: { eq: "owners/choose-right-builder/image.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 1800) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<StyledGatsbyImage
			fluid={data.image.childImageSharp.fluid}
			alt="qualified builders image"
		/>
	)
}

export default QualifiedBuildersImage
