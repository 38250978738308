// @flow
import * as React from 'react'
import styled from 'styled-components'

import { InfoAndImage } from 'views/components'
import Image from './image'
import { laptop, tablet } from 'shared/media-queries'

const Header = styled.div`
	width: 481px;
	${laptop} {
		width: 390px;
	}
	${tablet} {
		width: unset;
	}
`

const Collaborate: React.FC = () => (
	<InfoAndImage
		imageOnRight
		image={<Image />}
		title="Collaboration"
		subtitle={<Header>Collaborate with every project stakeholder.</Header>}
		bulletItems={[
			'Message directly with contractors and other stakeholders',
			'See qualification data from your risk team',
			'Work from a shared calendar',
			'Stay on the same page with team notes',
		]}
		verticallyCentered
		spacerWidths={['80px', '40px']}
	/>
)

export default Collaborate
