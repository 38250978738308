// @flow
import * as React from 'react'
import styled from 'styled-components'

import { InfoAndImage } from 'views/components'
import Image from './image'
import { tablet } from 'shared/media-queries'

const Header = styled.div`
	width: 420px;
	${tablet} {
		width: unset;
	}
`

const ChooseRightBuilder: React.FC = () => (
	<InfoAndImage
		image={<Image />}
		title="Bid Leveling"
		subtitle={<Header>Choose the right builder for every project.</Header>}
		bulletItems={[
			'Use custom bid forms to standardize the format of all proposals for easy apples-to-apples comparison',
			'Templatize bid forms, plug values, and compare bid versions to save time and level bids with ease',
			'Ensure the scope of work is accurate by exporting a detailed view of all bidding costs to Excel',
		]}
		verticallyCentered
		spacerWidths={['80px', '40px']}
		ctaTitle="Learn more"
		ctaLink="/bid-leveling"
	/>
)

export default ChooseRightBuilder
