// @flow
import * as React from 'react'
import styled from 'styled-components'

import { Spacer, ContentWrapper } from 'views/components'
import { gray02, gray65 } from 'shared/colors'
import Ucsf from './ucsf.svg'
import Greystar from './greystar.svg'
import Related from './related.svg'
import Fisher from './fisher.svg'

const Container = styled.div`
	background-color: ${gray02};
	text-align: center;
`
const Text = styled.div`
	font-weight: 600;
	font-size: 20px;
	color: ${gray65};
`
const Logos = styled.div`
	max-width: 1000px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-around;
`
const LogoSpacer = styled.div`
	flex: 0 0 20px;
`

const OwnersLogos: React.FC = () => (
	<Container>
		<ContentWrapper>
			<Spacer size={32} />
			<Text>Trusted by North America’s top owners</Text>
			<Spacer size={16} />
			<Logos>
				<Ucsf />
				<LogoSpacer />
				<Greystar />
				<LogoSpacer />
				<Related />
				<LogoSpacer />
				<Fisher />
			</Logos>
			<Spacer size={32} />
		</ContentWrapper>
	</Container>
)

export default OwnersLogos
