// @flow
import * as React from 'react'
import styled from 'styled-components'

import { Hero, H1, H3 } from 'views/components'
import BackgroundImage from './background-image'
import { lightBlue } from 'shared/colors'
import { mobile, laptop, tablet } from 'shared/media-queries'

const Heading = styled(H1)`
	max-width: 500px;
	${laptop} {
		font-size: 54px;
	}
	${tablet} {
		margin: 0 auto;
	}
	${mobile} {
		max-width: 390px;
		font-size: 32px;
	}
`
const SubHeading = styled(H3)`
	max-width: 450px;
	${tablet} {
		margin: 0 auto;
	}
	${mobile} {
		max-width: 410px;
	}
`

const Header: React.FC = () => (
	<Hero
		wordingWidth="none"
		backgroundImage={<BackgroundImage />}
		heading={<Heading>Finish projects on time and on budget.</Heading>}
		subHeading={
			<SubHeading>
				Discover the best builders with the bid management and qualification
				solution owners trust.
			</SubHeading>
		}
		ctaColor={lightBlue}
	/>
)

export default Header
