// @flow
import * as React from 'react'
import styled from 'styled-components'

import { InfoAndImage } from 'views/components'
import Image from './image'
import { tablet } from 'shared/media-queries'

const Header = styled.div`
	width: 500px;
	${tablet} {
		width: unset;
	}
`

const QualifiedBuilders: React.FC = () => (
	<InfoAndImage
		imageOnRight
		image={<Image />}
		title="Risk Management"
		subtitle={
			<Header>
				Reduce project risk with access to a growing network of qualified
				contractors.
			</Header>
		}
		bulletItems={[
			'Qualify more general contractors and subcontractors in less time with automated and customizable workflows',
			'Get project-specific risk assessments and mitigation recommendations',
			'Sync with ERP/accounting systems, or use open API',
		]}
		ctaTitle="Learn more"
		ctaLink="/tradetapp"
		verticallyCentered
		spacerWidths={['80px', '40px']}
	/>
)

export default QualifiedBuilders
