// @flow
import * as React from 'react'
import styled from 'styled-components'

import { H2, Spacer, H3, Title } from 'views/components'
import { grayText, lightBlue } from 'shared/colors'
import Image from './image'

const Container = styled.div`
	text-align: center;
`
const SubText = styled(H3)`
	color: ${grayText};
	max-width: 610px;
	margin: 0 auto;
`

const QualifiedBuilders: React.FC = () => (
	<Container>
		<Title wording="Network" color={lightBlue} />
		<Spacer size={15} />
		<H2>Find qualified builders for any job.</H2>
		<Spacer size={15} />
		<SubText>
			Connect with over 1 million GCs and Subcontractors on the largest
			construction network that’s updated in real time.
		</SubText>
		<Image />
	</Container>
)

export default QualifiedBuilders
