// @flow
import * as React from 'react'
import Layout from 'components/layout'
import {
	SectionSpacer,
	SectionBreak,
	ContentWrapper,
	Footer,
} from 'views/components'

import Header from './header'
import Logos from './logos'
import QualifiedBuilders from './qualified-builders'
import ReduceRisk from './reduce-risk'
import ChooseRightBuilder from './choose-right-builder'
import Collaborate from './collaborate'
import { lightBlue } from 'shared/colors'

const OwnersLP: React.FC = () => (
	<Layout
		title="Preconstruction Software for Owners | BuildingConnected"
		description="Tap into the world's largest network of general contractors and subcontractors and get more transparency into the preconstruction process."
	>
		<Header />
		<Logos />
		<SectionSpacer />
		<ContentWrapper>
			<QualifiedBuilders />
			<SectionBreak />
			<ReduceRisk />
			<SectionBreak />
			<ChooseRightBuilder />
			<SectionBreak />
			<Collaborate />
		</ContentWrapper>
		<SectionSpacer />
		<Footer
			wording="Join BuildingConnected for free today—and get building."
			wordingMaxWidth="725px"
			demoButtonWording="Get a free trial"
			demoButtonPadding="55px !important"
			ctaColor={lightBlue}
		/>
	</Layout>
)

export default OwnersLP
